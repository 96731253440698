body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@CHARSET "UTF-8";


div#bandeauId
{
	
	width : 100% ;
/* 	height : 500px ; */
/* 	background-color : #ff8000 ; */
/* 	background-color : #202020 ; */
	float : left ;
 	background-image : url(/static/media/back3.57d769fd.png); 
/* 	background-repeat: repeat-x;  */
	background-repeat: no-repeat;
	text-align : left ;
	color : white ;
	background-color : black ;
	/*
	position        : fixed;
	z-index: 4;
	*/
}
img#logoImageId
{
	width : 100%;
	float : left ;
}
div#logoDiv
	{
		float : left;
		/*height : 100px;*/
/* 		width : 150px; */
/* 		margin-top : 3px ;  */
/* 		margin-bottom : 3px ;  */
/* 		margin-left : 10px ; */
}

div#teleAdrDiv
{
	float : left;
/* 	border : 1px solid green ; */
/* 	background-image : url(../appImages/back/back3.png)  ;  */
	
}

.adresseDiv
{
	float : left ;
	width : 220px ;
	
	margin-top : 65px;
	background-color : white ;
	color : black ;
	
}

/* .telephoneDiv */
/* { */
/* 	width : 100% ; */
/* 	font-size : 1.8em; */
/* 	font-weight : bold ; */
/* 	text-align : center; */
/* 	color : red ; */
/* 	float : left ; */
/* 	background-color : #202020; */
/* }	 */
/* .welcomeMsgDiv */
/* { */
/* 	width : 100% ; */
/* 	font-size : 1.1em; */
/* 	font-weight : bold ; */
/* 	text-align : center; */
/* 	color : red ; */
/* 	margin : auto ; */
/* 	float : left ; */
/* } */

.retDivClass
{
	width : 100% ;
	float:left ;
	text-align : left ;
	color : red ;
	font-weight : bold ;
	font-size : 1.2em ;
}	
	

@media screen and ( min-width : 1200px ) and ( max-width : 2000px )
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 200px ; */
/* 	} */
	
	.logoDivClassResp
	{
		width : 8%;
/* 		border : 1px solid red ; */
	}
	.teleAdrDivClassResp
	{
		width : 92%;
		height : 100px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
	
	
	
}

@media screen and ( min-width : 1000px ) and ( max-width : 1200px )
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 180px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 9%;
/* 		border : 1px solid green ; */
		
		
	}
	.teleAdrDivClassResp
	{
		width : 91%;
		height : 100px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
}

@media screen and ( min-width : 800px ) and ( max-width : 1000px )
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 160px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 11%;
/* 		border : 1px solid yellow ; */
	}
	.teleAdrDivClassResp
	{
		width : 89%;
		height : 100px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
}

@media screen and ( min-width : 600px ) and ( max-width : 800px )
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 140px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 14%;
/* 		border : 1px solid pink ; */
	}
	.teleAdrDivClassResp
	{
		width : 86%;
		height : 100px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
	
}

@media screen and ( min-width : 500px ) and ( max-width : 600px ) 
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 140px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 18%;
/* 		border : 1px solid blue ; */
	}
	.teleAdrDivClassResp
	{
		width : 82%;
		height : 80px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
	
}

@media screen and ( min-width : 400px ) and ( max-width : 500px ) 
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 140px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 22%;
/* 		border : 1px solid yellow ; */
	}
	.teleAdrDivClassResp
	{
		width : 78%;
		height : 80px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
	
}

@media screen and ( min-width : 200px ) and ( max-width : 400px ) 
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 140px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 30%;
/* 		border : 1px solid green ; */
	}
	.teleAdrDivClassResp
	{
		width : 70%;
		height : 90px ;
	}
	.adresseDivResp
	{
		margin-left : 20px ;
	}	
	
}

@media screen  and ( max-width : 200px ) 
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 140px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 40%;
/* 		border : 1px solid white ; */
	}
	.teleAdrDivClassResp
	{
		width : 60%;
		height : 80px ;
	}
	.adresseDivResp
	{
		margin-left : 10px ;
	}	
	
}



@CHARSET "UTF-8";

.infoDivSous
{ 
	background-color : white ;
	margin : auto ;
	margin-top : 20px;
	border-radius : 10px 10px 0 0 ;
	border : 2px solid black ;
	font-size : 1em ;
	text-align : left ;
	-webkit-text-decoration : justify ;
	        text-decoration : justify ;
	background-color : black ;
} 
.infoTitle
{
	background-color : black ;
	color : #AFE1AF  ;
	width : 100% ;
	/*height : 50px ;*/
	border-radius : 10px 10px 0 0 ;
	text-align : center ;
	font-size : 1.3em;
	font-weight : bold ;
}

.infoPhotoClass
{
	width : 100% ;
	margin : auto ;
	/*margin-buttom : 0px;*/
}

.parInfoClass
{
	
	margin : 7px;
	
	text-align: justify;
    text-justify: inter-word;
}

.infoDivTextDiv
{
	float : left ;
	width : 100% ;
	background-color : white;
	-webkit-text-decoration : justify ;
	        text-decoration : justify ;
}


div#evaluationDivScrollId
{
	width : 100%;
	height : 270px ;
	background-color : white ;
	margin : auto ;
	
	text-align : left ;
	
	/*border-radius : 10px 10px 0 0 ;*/
	/*background-color : #E0E0E0 ;*/
	overflow: auto;
}









.infoDivBorder
{
	
}




@media screen and (min-width : 1200px)
{
	
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
	}
	.infoDiv
	{
		float :left ;
		width : 25%;
		/*height : 350px ;*/
	}
	.infoDivSousResp
	{
		width : 90% ;
		/*height : 350px;*/
		
/* 		background-color : white ; */
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 2px solid black ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
	}
	
	
}



@media screen and (min-width : 900px) and (max-width : 1200px) 
{
	
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
	}
	.infoDiv
	{
		float :left ;
		width : 25%;
	}
	.infoDivSousResp
	{
		width : 90% ;
		/*height : 350px;*/
		
/* 		background-color : white ; */
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 2px solid black ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
	}
	
	
}



@media screen and (min-width : 500px) and (max-width : 900px) and (orientation : landscape)
{
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
		margin-top : 10px ;
		/*background-color : red ;*/
		
	}
	.infoDiv
	{
		float :left ;
		width : 50%;
		/*height : 350px ;*/
		margin-top : 30px ;
	}
	.infoDivSousResp
	{
		width : 90% ;
		/*height : 350px;*/
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 1px solid grey ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
	}
	
}



@media screen and (min-width : 500px) and (max-width : 900px) and (orientation : portrait)
{
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
		margin-top : 10px ;
		/*background-color : red ;*/
		
	}
	.infoDiv
	{
		float :left ;
		width : 50%;
		/*height : 350px ;*/
		margin-top : 30px ;
	}
	.infoDivSousResp
	{
		width : 90% ;
		/*height : 350px;*/
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 1px solid grey ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
	}
	
	
}


@media screen and (min-width : 250px) and (max-width : 500px) 
{
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
		/*margin-top : 10px ;*/
		/*background-color : red ;*/
		
	}
	.infoDiv
	{
		float :left ;
		width : 100%;
		/*height : 350px ;*/
		margin-top : 20px ;
	}

	.infoDivSousResp
	{
		width : 99% ;
		/*height : 350px;*/
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 1px solid grey ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
/* 		float : left ; */
	}
	
	
}


@media screen and (min-width : 10px) and (max-width : 250px) 
{
	
	div#infoDivContainer
	{
		width : 100%;
		float :left;
		margin-top : 10px ;
		/*background-color : red ;*/
		
	}
	.infoDiv
	{
		float :left ;
		width : 100%;
		/*height : 350px ;*/
		margin-top : 20px ;
	}

	.infoDivSousResp
	{
		width : 99% ;
/* 		height : 350px; */
/* 		margin : auto ; */
/* 		margin-top : 20px; */
/* 		border-radius : 10px 10px 0 0 ; */
/* 		border : 1px solid grey ; */
/* 		font-size : 1em ; */
/* 		text-align : left ; */
/* 		text-decoration : justify ; */
/* 		background-color : black ; */
/* 		float : left ; */
	}
	
	
}



div#footer1Id
{
	width : 100% ;
	/*height : 150px ;*/
	background-color : black;
	margin : auto ;
	margin-top: 30px ;
	float : left ;
	/*margin-top : 30px ;*/
}

div#ConnectionDiv
{
	float : right ;
	width : 60px ;
	text-align : right ;
	color : white ;
	/*height : 100px ;*/
	/*background-color : red ;*/
}
div#connexionDivMarg
{
	float : right ;
	width : 70px ;
	height : 70px ;
	/*background-color : green ;*/
	margin-right : 10px ;
	margin-top : 5px ;
	font-size : 1.1em ;
	border : 1px solid white ;
	border-radius : 35px;
	text-align : center ;
	vertical-align : top;
 	line-height : 15px ; 
	
}

div#connexionDivMarg a
{
	text-decoration : none ;
	color : grey ;
}

/* .connexionInputClass */
/* { */
/* 	width : 150px ; */
/* 	/*mardin-top : 10px ;*/ */
	
/* } */
/* .connexionButtonClass */
/* { */
/* 	width : 150px ; */
/* 	background-color : red ; */
/* 	height : 30px ; */
/* 	border-radius : 5px ; */
/* 	color : white ; */
/* 	/*mardin-top : 10px ;*/ */
	
/* } */
/* .connexionInputDivClass */
/* { */
/* 	width : 180px; */
/* 	/*background : blue ;*/ */
/* 	text-align : right ; */
/* 	margin-top : 5px ; */
/* } */

span#refDiv
{
	background-color : grey ;
	color : #202020 ;
	float : left ;
	width : 100%;
}

 
 span#refDiv a {
 text-decoration: none;
 color : black ;
 }
 
 div#rsDiv
 {
 	margin-top : 50px ;
 	text-align : center ;
 }
 
 .footerMenuDiv
 {
 	float : left ;
 	width : 100% ;
 	color : grey ;
 	margin-top : 30px ;
 	margin-bottom : 30px ;
 }
 .footerOneMenuDiv
 {
 	float : left;
 	
 	text-align : center ;
 }
 .footerOneMenuDivMarge
 {
 	margin : auto ;
 	width : 60px;
 	text-align : left ;
 	font-weight : bold ;
 	font-size : 1.2em;
 }
 .footerOneMenuDivMarge a 
 {
 	text-decoration: none;
 	color : grey ;
 }

 .copyrightDiv
 {
 	color : grey ;
 	float : left ;
 	text-align : center ;
 	width : 100% ; 
 }
 
 .copyrightDiv a
 {
 	text-decoration : none ;
 	color : grey;
 }
 
 .copyrightDiv a:hover
 {
 	color : white ;
 }
 
@media screen and (min-width : 600px )
{
	.footerOneMenuDivResp
	{
		width :25%;
	}
}

@media screen and (min-width : 200px ) and (max-width : 600px)
{
	.footerOneMenuDivResp
	{
		width :50%;
	}
}

@media screen and (max-width : 200px )
{
	.footerOneMenuDivResp
	{
		width :100%;
	}
}
 
 



.bodyClass
{
	
	padding : 0px ;
	margin : 0px;
	text-align : center ;
	/*Background-color : black ;*/
	
}


.telCommandDiv
{
	background-color :  #000000;
	float : left ;
	width : 100% ;
	text-align : center ;
	
}

.telCommandDiv a
{
	text-decoration : none ;
	color : red ;
}

.telCommandDivMarge
{
	
	margin : auto ;
	margin-top : 5px;
	/*text-align : center ;*/
	border : 1px solid red ;
	
	font-size : 18px ;
	font-weight : bold ;
	border-radius : 10px ;
	padding-top : 5px ;
	padding-bottom : 5px ;
	
	
	
	
	
	
} 






a:link
{
	text-decoration : none ;
}

div#beforFooter
{
	height : 30px ;
	width : 100% ;
	float : left ;
}


@media screen and (min-width : 400px) 
{
	.telCommandDivMargeResp
	{
		width : 395px ; 
		height : 40px ;
	}
}

@media screen and (max-width : 400px)
{
	.telCommandDivMargeResp
	{
		width : 207px;
		height : 72px;
		
	}
}



@CHARSET "UTF-8";

.menuDivId
{
	
	background-color : #AFE1AF  ;
	
	width : 100% ;
	float : left ;
	
	
	
	
	text-align : left ;
	
	float : left ;
	
}

/*
.menuItemDiv a 
{
	text-decoration : none ;
	color : white ;
}
*/


.menuItemDiv
{
	color : black ;
	float : left ;
	height : 35px ;
	
	
	text-align : left ;
	font-size : 1.2em;
	
	margin-top :9px ;
	cursor: pointer;
	
}



.divMenuIconBlack
{
	width : 100% ;
	float : left ;
	height:30px ;
	background-color : #AFE1AF ;
}

.divMenuIconGrey
{
	width : 100% ;
	float : left ;
	height:30px ;
	background-color : #202020;
}

span#hiddingMenuDivForMenu
{
	
	float : right ;
	font-size : 1.5em;
	color : white ;
}

@media screen and (min-width : 600px) {
	.menuItemDivResp{
		width : 25% ;
	}
	.divMenuIconResp{
		display: none;
	}
}

@media screen and (max-width : 600px) {
	.menuItemDivResp{
		width : 100% ;
	}
	.divMenuIconResp{
		display: block;
	}
}







.telCommandDiv
{
	background-color :  black;
/* 	border : 1px solid white ; */
	float : left ;
	width : 100% ;
	text-align : center ;
	
}

.telCommandDiv a
{
	text-decoration : none ;
	color : red ;
}

.telCommandDivMarge
{
	
	margin : auto ;
	margin-top : 5px;
	/*text-align : center ;*/
	border : 1px solid red ;
	
	font-size : 16px ;
	font-weight : bold ;
	border-radius : 10px ;
	padding-top : 3px ;
	padding : 5px ;
	
	
	
	
	
	
	
	
} 


.commandEnLigneDiv
	{
		float : left ;
		height : 30px ;
		/*width : 200px;*/
		
		
		
		
	}
	
	.paiementDiv
	{
		float : left ;
		height : 30px ;
		
		
		/*width : 190px;*/
		
	}

@media screen and (min-width : 402px)
{
	.telCommandDivMargeResp
	{
		max-width : 368px ; 
		height : 35px ;
		
		
	}
}

@media screen and (max-width : 402px)
{
	.telCommandDivMargeResp
	{
		max-width : 190px ; 
		height : 70px ;
	}
}
.content1Div
{
	width : 100% ;
	/*height : 500px;*/
	/*background-image : url(../appImages/back/background1.jpg);*/
	float : left ;
/* 	background-color : white; */
	background-color : black;
}

.centePerCentWidthAndFloatContainer{
	width : 100% ;
	float : left ;
}

.rowContainer
{
	margin : auto;
	
	
}

.cartDiv
{
	text-align : center ;
	
	width : 234px;
	height : 304px;
/* 	border : 1px solid white; */
	
	
	/*border : 1px solid red ;*/
	
}

.carteDivMarge
{
	border : 2px solid #AFE1AF  ;
	margin : 10px ;
	border-radius : 112px ;
	background-color : white ;
	width : 224px;
	height : 224px;
	background-color : #303030 ;
}

.carteImageClass
{
	width : 90% ;
/* 	border-radius : 10px ; */
}

.carteLabelClass
{
	width : 86% ;
	text-align : center ;
	font-size : 1.2em ;
	font-weight : bold ;
	color : black  ;
	background-color : #AFE1AF  ;
	margin : auto ;
}







@media screen and (min-width : 1000px)
{
	.rowContainerResp
	{
		width : 80%;
	}
	.cartDivResp 
	{ 
 		float : left ;
 	} 
}

@media screen and (min-width : 568px) and (max-width : 1000px)
{
	.rowContainerResp
	{
		width : 85%;
	}
 	.cartDivResp 
	{ 
 		float : left ;
 	} 
}


@media screen and (min-width : 500px) and (max-width : 568px)
{
	.rowContainerResp
	{
		width : 98%;
	}
 	.cartDivResp 
	{ 
 		float : left ;
 	} 
}

@media screen and (max-width : 500px)
{
	.rowContainerResp
	{
		width : 98%;
		
	}
	.cartDivResp 
	{ 
 		margin : auto ;
 	} 
}





.mapClass{
    float : left ;
    width : 100% ;
    height: 600px ;
    text-align : center;
    margin-top : 40px ;
    
}

.mapClassMarge{
    
    width : 100% ;
    height: 550px ;
    margin : auto ;
    /*border : 5px solid black ;*/
    /*border-bottom : 5px solid black ;*/
    text-align: center;
}

