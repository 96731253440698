@CHARSET "UTF-8";


div#bandeauId
{
	
	width : 100% ;
/* 	height : 500px ; */
/* 	background-color : #ff8000 ; */
/* 	background-color : #202020 ; */
	float : left ;
 	background-image : url(../../data/appData/appImages/back/back3.png)  ; 
/* 	background-repeat: repeat-x;  */
	background-repeat: no-repeat;
	text-align : left ;
	color : white ;
	background-color : black ;
	/*
	position        : fixed;
	z-index: 4;
	*/
}
img#logoImageId
{
	width : 100%;
	float : left ;
}
div#logoDiv
	{
		float : left;
		/*height : 100px;*/
/* 		width : 150px; */
/* 		margin-top : 3px ;  */
/* 		margin-bottom : 3px ;  */
/* 		margin-left : 10px ; */
}

div#teleAdrDiv
{
	float : left;
/* 	border : 1px solid green ; */
/* 	background-image : url(../appImages/back/back3.png)  ;  */
	
}

.adresseDiv
{
	float : left ;
	width : 220px ;
	
	margin-top : 65px;
	background-color : white ;
	color : black ;
	
}

/* .telephoneDiv */
/* { */
/* 	width : 100% ; */
/* 	font-size : 1.8em; */
/* 	font-weight : bold ; */
/* 	text-align : center; */
/* 	color : red ; */
/* 	float : left ; */
/* 	background-color : #202020; */
/* }	 */
/* .welcomeMsgDiv */
/* { */
/* 	width : 100% ; */
/* 	font-size : 1.1em; */
/* 	font-weight : bold ; */
/* 	text-align : center; */
/* 	color : red ; */
/* 	margin : auto ; */
/* 	float : left ; */
/* } */

.retDivClass
{
	width : 100% ;
	float:left ;
	text-align : left ;
	color : red ;
	font-weight : bold ;
	font-size : 1.2em ;
}	
	

@media screen and ( min-width : 1200px ) and ( max-width : 2000px )
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 200px ; */
/* 	} */
	
	.logoDivClassResp
	{
		width : 8%;
/* 		border : 1px solid red ; */
	}
	.teleAdrDivClassResp
	{
		width : 92%;
		height : 100px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
	
	
	
}

@media screen and ( min-width : 1000px ) and ( max-width : 1200px )
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 180px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 9%;
/* 		border : 1px solid green ; */
		
		
	}
	.teleAdrDivClassResp
	{
		width : 91%;
		height : 100px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
}

@media screen and ( min-width : 800px ) and ( max-width : 1000px )
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 160px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 11%;
/* 		border : 1px solid yellow ; */
	}
	.teleAdrDivClassResp
	{
		width : 89%;
		height : 100px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
}

@media screen and ( min-width : 600px ) and ( max-width : 800px )
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 140px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 14%;
/* 		border : 1px solid pink ; */
	}
	.teleAdrDivClassResp
	{
		width : 86%;
		height : 100px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
	
}

@media screen and ( min-width : 500px ) and ( max-width : 600px ) 
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 140px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 18%;
/* 		border : 1px solid blue ; */
	}
	.teleAdrDivClassResp
	{
		width : 82%;
		height : 80px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
	
}

@media screen and ( min-width : 400px ) and ( max-width : 500px ) 
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 140px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 22%;
/* 		border : 1px solid yellow ; */
	}
	.teleAdrDivClassResp
	{
		width : 78%;
		height : 80px ;
	}
	.adresseDivResp
	{
		margin-left : 30px ;
	}	
	
}

@media screen and ( min-width : 200px ) and ( max-width : 400px ) 
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 140px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 30%;
/* 		border : 1px solid green ; */
	}
	.teleAdrDivClassResp
	{
		width : 70%;
		height : 90px ;
	}
	.adresseDivResp
	{
		margin-left : 20px ;
	}	
	
}

@media screen  and ( max-width : 200px ) 
{
/* 	.bandeauClassResp */
/* 	{ */
/* 		height : 140px ; */
/* 	} */
	.logoDivClassResp
	{
		width : 40%;
/* 		border : 1px solid white ; */
	}
	.teleAdrDivClassResp
	{
		width : 60%;
		height : 80px ;
	}
	.adresseDivResp
	{
		margin-left : 10px ;
	}	
	
}


