
.telCommandDiv
{
	background-color :  black;
/* 	border : 1px solid white ; */
	float : left ;
	width : 100% ;
	text-align : center ;
	
}

.telCommandDiv a
{
	text-decoration : none ;
	color : red ;
}

.telCommandDivMarge
{
	
	margin : auto ;
	margin-top : 5px;
	/*text-align : center ;*/
	border : 1px solid red ;
	
	font-size : 16px ;
	font-weight : bold ;
	border-radius : 10px ;
	padding-top : 3px ;
	padding : 5px ;
	
	
	
	
	
	
	
	
} 


.commandEnLigneDiv
	{
		float : left ;
		height : 30px ;
		/*width : 200px;*/
		
		
		
		
	}
	
	.paiementDiv
	{
		float : left ;
		height : 30px ;
		
		
		/*width : 190px;*/
		
	}

@media screen and (min-width : 402px)
{
	.telCommandDivMargeResp
	{
		max-width : 368px ; 
		height : 35px ;
		
		
	}
}

@media screen and (max-width : 402px)
{
	.telCommandDivMargeResp
	{
		max-width : 190px ; 
		height : 70px ;
	}
}