

.bodyClass
{
	
	padding : 0px ;
	margin : 0px;
	text-align : center ;
	/*Background-color : black ;*/
	
}


.telCommandDiv
{
	background-color :  #000000;
	float : left ;
	width : 100% ;
	text-align : center ;
	
}

.telCommandDiv a
{
	text-decoration : none ;
	color : red ;
}

.telCommandDivMarge
{
	
	margin : auto ;
	margin-top : 5px;
	/*text-align : center ;*/
	border : 1px solid red ;
	
	font-size : 18px ;
	font-weight : bold ;
	border-radius : 10px ;
	padding-top : 5px ;
	padding-bottom : 5px ;
	
	
	
	
	
	
} 






a:link
{
	text-decoration : none ;
}

div#beforFooter
{
	height : 30px ;
	width : 100% ;
	float : left ;
}


@media screen and (min-width : 400px) 
{
	.telCommandDivMargeResp
	{
		width : 395px ; 
		height : 40px ;
	}
}

@media screen and (max-width : 400px)
{
	.telCommandDivMargeResp
	{
		width : 207px;
		height : 72px;
		
	}
}


